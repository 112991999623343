<template>
    <div>
        <div class="no_favs" v-if="contacts_computed.length == 0 && fav_pages.length == 0 && fav_events.length == 0 && fav_downloads.length == 0">
            <p>Sie haben bisher noch keine Favoriten hinzugefügt.</p>
        </div>
        <div class="accordion"  v-if="contacts_computed.length > 0">
            <div class="header" :class="{ 'active' : show_1 }" v-on:click="toggle(1)">
                Kontakte
            </div>
            <transition name="accordion"
                v-on:before-enter="beforeEnter" v-on:enter="enter"
                v-on:before-leave="beforeLeave" v-on:leave="leave"
            >
                <div class="body" v-show="show_1">
                    <div class="body-inner">
                        <Contact @handleFavourites="get_favourite_contacts" :contactData="contacts_computed" active_el="favoriten"></Contact>
                    </div>
                </div>
            </transition>
        </div>
        <div class="accordion"  v-if="fav_pages_computed.length > 0">
            <div class="header" :class="{ 'active' : show_2 }" v-on:click="toggle(2)">
                Seiten
            </div>
            <transition name="accordion"
                        v-on:before-enter="beforeEnter" v-on:enter="enter"
                        v-on:before-leave="beforeLeave" v-on:leave="leave"
            >
                <div class="body" v-show="show_2">
                    <div class="body-inner">
                        <div v-for="(item,index) in fav_pages_computed" v-bind:key="index" class="fav_page_wrapper">
                            <a rel="noindex, nofollow" class="fav_page_link" :class="item.state" :href="item.uri">{{item.title}}<p v-if="item.state=='expired'">Seite nicht mehr verfügbar</p></a>
                            <div class="delete" @click="delete_fav_page(index)"></div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
        <div class="accordion"  v-if="fav_events.length > 0">
            <div class="header" :class="{ 'active' : show_3 }" v-on:click="toggle(3)">
                Veranstaltungen
            </div>
            <transition name="accordion"
                        v-on:before-enter="beforeEnter" v-on:enter="enter"
                        v-on:before-leave="beforeLeave" v-on:leave="leave"
            >
                <div class="body" v-show="show_3">
                    <div class="body-inner">
                        <div v-for="(item,index) in fav_events" v-bind:key="index"  class="fav_event_wrapper">
                            <a :href="item.link" class="fav_event_link">{{ item.title }} ({{ item.date }})</a>
                            <div class="delete" @click="delete_fav_event(index)"></div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
        <div class="accordion"  v-if="fav_downloads.length > 0">
            <div class="header" :class="{ 'active' : show_4 }" v-on:click="toggle(4)">
                Downloads
            </div>
            <transition name="accordion"
                        v-on:before-enter="beforeEnter" v-on:enter="enter"
                        v-on:before-leave="beforeLeave" v-on:leave="leave"
            >
                <div class="body" v-show="show_4">
                    <div class="body-inner">
                        <div v-for="(item,index) in fav_downloads" v-bind:key="index"  class="fav_event_wrapper">
                            <a rel="noindex, nofollow" :href="item.url" :class="item.state" class="fav_event_link" target="_blank">{{ item.title }}<p v-if="item.state=='expired'">Download nicht mehr verfügbar</p></a>
                            <div class="delete" @click="delete_fav_download(index)"></div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
    import Contact from "@/components/sidebarComponents/childComponents/Contact";
    import axios from "axios";
    export default {
        name: "Favourites",
        components: {Contact},
        props: {
            contactsData: Array
        },
        data: () => ({
            fav_contacts: [],
            contacts_acc: false,
            show_1: false,
            show_2: false,
            show_3: false,
            show_4: false,
            fav_pages: [],
            fav_events: [],
            fav_downloads: []
        }),
        methods: {
            checkUrls(area) {
                if(area == "pages") {
                    this.fav_pages.forEach((page) => {
                        let url = page.uri;
                        axios({
                            method: 'get',
                            url: url
                        }).then((response) => {
                            if(response.status == "404") {
                                page.state = "expired";
                            } else {
                                page.state = "valid";
                            }
                        }).catch(() => {
                            page.state = "expired";
                        });
                    });
                } else if(area == "downloads") {
                    this.fav_downloads.forEach((page) => {
                        let url = page.url;
                        axios({
                            method: 'get',
                            url: url
                        }).then((response) => {
                            if(response.status == "404") {
                                page.state = "expired";
                            } else {
                                page.state = "valid";
                            }
                        }).catch(() => {
                            page.state = "expired";
                        });
                    });
                }
            },
            delete_fav_page(index) {
                this.fav_pages.splice(index, 1);
                localStorage.setItem("fav_pages", JSON.stringify(this.fav_pages));
                this.get_favourite_pages();
                window.check_if_page_is_favourite();
                this.$emit('count_total');
            },
            delete_fav_download(index) {
                this.fav_downloads.splice(index, 1);
                localStorage.setItem("fav_downloads", JSON.stringify(this.fav_downloads));
                this.get_favourite_downloads();
                window.check_fav_downloads();
                this.$emit('count_total');
            },
            delete_fav_event(index) {
                this.fav_events.splice(index, 1);
                let fav_events_list = "";
                for(var i=0; i < this.fav_events.length; i++) {
                    // fav_events_list += this.fav_events[i].id;
                    fav_events_list += this.fav_events[i].uuid;
                    if(this.fav_events.length - i > 1) {
                        fav_events_list += ",";
                    }
                }
                localStorage.setItem("favorite_events", fav_events_list);
                this.get_favorite_events();

                window.vue_event_calendar.$children[0].check_favourites();
                this.$emit('count_total');
            },
            get_favourite_contacts() {
                var fav_ids = localStorage.favourite_contacts;
                this.fav_contacts = [];
                if(typeof(fav_ids) != 'undefined') {
                    fav_ids = fav_ids.split(";")
                    for(var i = 0; i < fav_ids.length; i++) {
                        for(var j = 0; j < this.contactsData.length; j++) {
                            if(fav_ids[i] == this.contactsData[j].id) {
                                this.contactsData[j].is_favourite = true;
                                this.fav_contacts.push(this.contactsData[j])
                                break
                            }
                        }
                    }
                }
                this.$emit('count_total');
            },
            get_favourite_pages() {
                let fav_pages = localStorage.getItem("fav_pages");
                if(fav_pages) {
                    this.fav_pages = JSON.parse(fav_pages);
                    this.checkUrls("pages");
                }
            },
            get_favourite_downloads() {
                let fav_downloads = localStorage.getItem("fav_downloads");
                if(fav_downloads) {
                    this.fav_downloads = JSON.parse(fav_downloads);
                    this.checkUrls("downloads");
                }
            },
            get_favorite_events() {
                let fav_events = localStorage.getItem("favorite_events");
                if(fav_events) {
                    fav_events = fav_events.split(",");
                    // for(var i=0; i < fav_events.length; i++) {
                    //     fav_events[i] = parseInt(fav_events[i]);
                    // }
                    fav_events = JSON.stringify(fav_events);
                    // eslint-disable-next-line no-console
                    //console.log(fav_events);
                    axios({
                        method: 'post',
                        // url: 'https://fs-api-rg.offizium.com/events/someevents',
                      // LIVE
                        url: 'https://aht1gh-api.sqronline.de/api/modules/events/getevents-post',
                      // DEV
                        // url: 'http://api.hallogemeinde.local/api/modules/events/getevents-post',
                        data: {
                            idArray: fav_events
                        }
                    }).then((response) => {
                        let data = response.data;
                        if(data) {
                            this.fav_events = [];
                            for(var i=0; i<data.length; i++) {
                                var sub_arr = [];
                                sub_arr.title = data[i].event_titel;
                                var startdate = data[i].event_startdatum;
                                var enddate = data[i].event_enddatum;
                                if(enddate && startdate != enddate) {
                                    enddate = enddate.split("-");
                                    enddate = " – "+enddate[2]+"."+enddate[1]+"."+enddate[0];
                                }
                                startdate = startdate.split("-");
                                startdate = startdate[2]+"."+startdate[1]+"."+startdate[0];
                                if(enddate && enddate != "") {
                                    startdate += enddate;
                                }
                                sub_arr.date = startdate;
                                sub_arr.id = data[i].id;
                                sub_arr.uuid = data[i].uuid;
                                // sub_arr.link = "/aktuelles/veranstaltungen/"+data[i].id;
                                // sub_arr.link = "/aktuelles/veranstaltungen?v="+data[i].id;
                                sub_arr.link = "/aktuelles/veranstaltungen?v="+data[i].uuid;
                                this.fav_events.push(sub_arr);
                            }
                        }
                    });
                }
            },
            toggle(acc) {
                if(acc == 1) {
                    this.show_1 = !this.show_1;
                    this.show_2 = false;
                    this.show_3 = false;
                    this.show_4 = false;
                } else if(acc == 2) {
                    this.show_2 = !this.show_2;
                    this.show_1 = false;
                    this.show_3 = false;
                    this.show_4 = false;
                } else if(acc == 3) {
                    this.show_3 = !this.show_3;
                    this.show_1 = false;
                    this.show_2 = false;
                    this.show_4 = false;
                } else if(acc == 4) {
                    this.show_4 = !this.show_4;
                    this.show_1 = false;
                    this.show_2 = false;
                    this.show_3 = false;
                }
            },
            beforeEnter: function(el) {
                el.style.height = '0';
            },
            enter: function(el) {
                el.style.height = el.scrollHeight + 'px';
            },
            beforeLeave: function(el) {
                el.style.height = el.scrollHeight + 'px';
            },
            leave: function(el) {
                el.style.height = '0';
            }
        },
        computed: {
          contacts_computed() {
              return this.fav_contacts;
          },
            fav_pages_computed() {
              return this.fav_pages;
            }
        },
        mounted() {
            this.get_favourite_contacts();
            this.get_favourite_pages();
            this.get_favorite_events();
            this.get_favourite_downloads();
        }
    }
</script>

<style scoped>
    .fav_page_wrapper, .fav_event_wrapper {
        position: relative;
    }
    .fav_page_wrapper .delete, .fav_event_wrapper .delete {
        position: absolute;
        height: 44px;
        width: 40px;
        background: url(../../assets/images/delete.svg) center center no-repeat;
        top: 0px;
        right: 10px;
        cursor: pointer;
    }
    .fav_page_wrapper:first-of-type .fav_page_link, .fav_event_wrapper:first-of-type .fav_event_link {
        margin-top: 40px;
    }
    .fav_page_link, .fav_event_link {
        display: block;
        text-transform: uppercase;
        padding: 12px 0px;
        padding-left: 15px;
        position: relative;
        background: #F4F4F5;
        padding-right: 50px;
        margin-bottom: 15px;
        background-position: left 4px;
        background-repeat: no-repeat;
        background-size: auto 44px;
        box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.1);
        transition: all 0.2s ease-in-out;
        margin-left: 10px;
        margin-right: 10px;
    }
    .fav_page_link:hover, .fav_event_link:hover {
        transform: scale(1.03);
    }
    .fav_page_link span, .fav_event_link span {
        height: 30px;
        width: 30px;
        position: absolute;
        right: -8px;
        top: 0px;
        bottom: 0px;
        margin: auto;
        background: #00862F;
    }
    .fav_page_link span::after, .fav_event_link span::after {
        content: "";
        position: absolute;
        left: 6px;
        top: 0px;
        bottom: 0px;
        margin: auto;
        height: 0px;
        border: solid #fff;
        border-width: 0 3px 3px 0;
        padding: 5px;
        transform: rotate(-45deg);
    }
    .fav_page_link::after, .fav_event_link::after {
        display: none;
    }
    a p {
        margin: 0px;
        font-size: 12px;
        margin-top: 5px;
    }
    a.expired {
        opacity: 0.5;
    }
</style>