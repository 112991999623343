<template>
  <div class="v_app_selector_prefix_servicemenu" id="sidebar_components">
    <Sidebar ref="Sidebar" @clickedSidebar="toggleOverlay"/>
    <transition name="fade">
      <div class="overlay_dark" @click="hideSidebar" v-if="showOverlay"></div>
    </transition>
  </div>
</template>

<script>
import Sidebar from './components/Sidebar.vue'

export default {
  name: 'app',
  components: {
    Sidebar
  },
  data() {
    return {
      showOverlay: false
    }
  },
  methods: {
    toggleOverlay: function(showSidebar) {
      this.showOverlay = showSidebar
    },
    hideSidebar() {
      this.$refs.Sidebar.closeSidebar(true)
    }
  }
}
</script>

<style>
#sidebar_components {
    font-family: 'Open Sans', Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /*text-align: center;*/
    color: #2c3e50;
    margin-top: 60px;
    font-size: 14px;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.overlay_dark {
  position: fixed;
  top:0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background: rgba(0,0,0,0.7);
  transition: all 0.3s ease;
  z-index: 9998;
}
.accordion {
  margin-bottom: 20px;
}
.accordion .header {
  position: relative;
  line-height: 1.4em;
  font-size: 16px;
  border-bottom: 2px solid #00862f;
  cursor: pointer;
  text-transform: uppercase;
  padding: 7px 0px 7px 30px;
}
.accordion .header::before {
  content: "";
  position: absolute;
  left: 7px;
  top: 11px;
  border: solid #00862f;
  border-width: 0 2px 2px 0;
  padding: 4px;
  transform: rotate(45deg);
  transition: all 0.2s ease-in-out;
}
.accordion .header.active::before {
  top: 15px;
  transform: rotate(225deg);
}
.accordion .body {
  overflow: hidden;
  transition: 150ms ease-out;
  padding-top: 20px;
  padding-bottom: 20px;
}
.accordion .body-inner {
  overflow-wrap: break-word;
}
.sidebar a {
  position: relative;
  text-decoration: none;
}
.sidebar a::after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: -3px;
  right: -3px;
  height: 7px;
  background: #00862f;
  opacity: 0.2;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.sidebar a:hover::after {
  height: 110%;
}

.v-application a { color: #1976d2; }
.v-application .primary {
  background-color: #1976d2 !important;
  border-color: #1976d2 !important;
}
.v-application .primary--text {
  color: #1976d2 !important;
  caret-color: #1976d2 !important;
}
.v-application .primary.lighten-5 {
  background-color: #c7fdff !important;
  border-color: #c7fdff !important;
}
.v-application .primary--text.text--lighten-5 {
  color: #c7fdff !important;
  caret-color: #c7fdff !important;
}
.v-application .primary.lighten-4 {
  background-color: #a8e0ff !important;
  border-color: #a8e0ff !important;
}
.v-application .primary--text.text--lighten-4 {
  color: #a8e0ff !important;
  caret-color: #a8e0ff !important;
}
.v-application .primary.lighten-3 {
  background-color: #8ac5ff !important;
  border-color: #8ac5ff !important;
}
.v-application .primary--text.text--lighten-3 {
  color: #8ac5ff !important;
  caret-color: #8ac5ff !important;
}
.v-application .primary.lighten-2 {
  background-color: #6aaaff !important;
  border-color: #6aaaff !important;
}
.v-application .primary--text.text--lighten-2 {
  color: #6aaaff !important;
  caret-color: #6aaaff !important;
}
.v-application .primary.lighten-1 {
  background-color: #488fef !important;
  border-color: #488fef !important;
}
.v-application .primary--text.text--lighten-1 {
  color: #488fef !important;
  caret-color: #488fef !important;
}
.v-application .primary.darken-1 {
  background-color: #005eb6 !important;
  border-color: #005eb6 !important;
}
.v-application .primary--text.text--darken-1 {
  color: #005eb6 !important;
  caret-color: #005eb6 !important;
}
.v-application .primary.darken-2 {
  background-color: #00479b !important;
  border-color: #00479b !important;
}
.v-application .primary--text.text--darken-2 {
  color: #00479b !important;
  caret-color: #00479b !important;
}
.v-application .primary.darken-3 {
  background-color: #003180 !important;
  border-color: #003180 !important;
}
.v-application .primary--text.text--darken-3 {
  color: #003180 !important;
  caret-color: #003180 !important;
}
.v-application .primary.darken-4 {
  background-color: #001e67 !important;
  border-color: #001e67 !important;
}
.v-application .primary--text.text--darken-4 {
  color: #001e67 !important;
  caret-color: #001e67 !important;
}
.v-application .secondary {
  background-color: #424242 !important;
  border-color: #424242 !important;
}
.v-application .secondary--text {
  color: #424242 !important;
  caret-color: #424242 !important;
}
.v-application .secondary.lighten-5 {
  background-color: #c1c1c1 !important;
  border-color: #c1c1c1 !important;
}
.v-application .secondary--text.text--lighten-5 {
  color: #c1c1c1 !important;
  caret-color: #c1c1c1 !important;
}
.v-application .secondary.lighten-4 {
  background-color: #a6a6a6 !important;
  border-color: #a6a6a6 !important;
}
.v-application .secondary--text.text--lighten-4 {
  color: #a6a6a6 !important;
  caret-color: #a6a6a6 !important;
}
.v-application .secondary.lighten-3 {
  background-color: #8b8b8b !important;
  border-color: #8b8b8b !important;
}
.v-application .secondary--text.text--lighten-3 {
  color: #8b8b8b !important;
  caret-color: #8b8b8b !important;
}
.v-application .secondary.lighten-2 {
  background-color: #727272 !important;
  border-color: #727272 !important;
}
.v-application .secondary--text.text--lighten-2 {
  color: #727272 !important;
  caret-color: #727272 !important;
}
.v-application .secondary.lighten-1 {
  background-color: #595959 !important;
  border-color: #595959 !important;
}
.v-application .secondary--text.text--lighten-1 {
  color: #595959 !important;
  caret-color: #595959 !important;
}
.v-application .secondary.darken-1 {
  background-color: #2c2c2c !important;
  border-color: #2c2c2c !important;
}
.v-application .secondary--text.text--darken-1 {
  color: #2c2c2c !important;
  caret-color: #2c2c2c !important;
}
.v-application .secondary.darken-2 {
  background-color: #171717 !important;
  border-color: #171717 !important;
}
.v-application .secondary--text.text--darken-2 {
  color: #171717 !important;
  caret-color: #171717 !important;
}
.v-application .secondary.darken-3 {
  background-color: #000000 !important;
  border-color: #000000 !important;
}
.v-application .secondary--text.text--darken-3 {
  color: #000000 !important;
  caret-color: #000000 !important;
}
.v-application .secondary.darken-4 {
  background-color: #000000 !important;
  border-color: #000000 !important;
}
.v-application .secondary--text.text--darken-4 {
  color: #000000 !important;
  caret-color: #000000 !important;
}
.v-application .accent {
  background-color: #82b1ff !important;
  border-color: #82b1ff !important;
}
.v-application .accent--text {
  color: #82b1ff !important;
  caret-color: #82b1ff !important;
}
.v-application .accent.lighten-5 {
  background-color: #ffffff !important;
  border-color: #ffffff !important;
}
.v-application .accent--text.text--lighten-5 {
  color: #ffffff !important;
  caret-color: #ffffff !important;
}
.v-application .accent.lighten-4 {
  background-color: #f8ffff !important;
  border-color: #f8ffff !important;
}
.v-application .accent--text.text--lighten-4 {
  color: #f8ffff !important;
  caret-color: #f8ffff !important;
}
.v-application .accent.lighten-3 {
  background-color: #daffff !important;
  border-color: #daffff !important;
}
.v-application .accent--text.text--lighten-3 {
  color: #daffff !important;
  caret-color: #daffff !important;
}
.v-application .accent.lighten-2 {
  background-color: #bce8ff !important;
  border-color: #bce8ff !important;
}
.v-application .accent--text.text--lighten-2 {
  color: #bce8ff !important;
  caret-color: #bce8ff !important;
}
.v-application .accent.lighten-1 {
  background-color: #9fccff !important;
  border-color: #9fccff !important;
}
.v-application .accent--text.text--lighten-1 {
  color: #9fccff !important;
  caret-color: #9fccff !important;
}
.v-application .accent.darken-1 {
  background-color: #6596e2 !important;
  border-color: #6596e2 !important;
}
.v-application .accent--text.text--darken-1 {
  color: #6596e2 !important;
  caret-color: #6596e2 !important;
}
.v-application .accent.darken-2 {
  background-color: #467dc6 !important;
  border-color: #467dc6 !important;
}
.v-application .accent--text.text--darken-2 {
  color: #467dc6 !important;
  caret-color: #467dc6 !important;
}
.v-application .accent.darken-3 {
  background-color: #2364aa !important;
  border-color: #2364aa !important;
}
.v-application .accent--text.text--darken-3 {
  color: #2364aa !important;
  caret-color: #2364aa !important;
}
.v-application .accent.darken-4 {
  background-color: #004c90 !important;
  border-color: #004c90 !important;
}
.v-application .accent--text.text--darken-4 {
  color: #004c90 !important;
  caret-color: #004c90 !important;
}
.v-application .error {
  background-color: #ff5252 !important;
  border-color: #ff5252 !important;
}
.v-application .error--text {
  color: #ff5252 !important;
  caret-color: #ff5252 !important;
}
.v-application .error.lighten-5 {
  background-color: #ffe4d5 !important;
  border-color: #ffe4d5 !important;
}
.v-application .error--text.text--lighten-5 {
  color: #ffe4d5 !important;
  caret-color: #ffe4d5 !important;
}
.v-application .error.lighten-4 {
  background-color: #ffc6b9 !important;
  border-color: #ffc6b9 !important;
}
.v-application .error--text.text--lighten-4 {
  color: #ffc6b9 !important;
  caret-color: #ffc6b9 !important;
}
.v-application .error.lighten-3 {
  background-color: #ffa99e !important;
  border-color: #ffa99e !important;
}
.v-application .error--text.text--lighten-3 {
  color: #ffa99e !important;
  caret-color: #ffa99e !important;
}
.v-application .error.lighten-2 {
  background-color: #ff8c84 !important;
  border-color: #ff8c84 !important;
}
.v-application .error--text.text--lighten-2 {
  color: #ff8c84 !important;
  caret-color: #ff8c84 !important;
}
.v-application .error.lighten-1 {
  background-color: #ff6f6a !important;
  border-color: #ff6f6a !important;
}
.v-application .error--text.text--lighten-1 {
  color: #ff6f6a !important;
  caret-color: #ff6f6a !important;
}
.v-application .error.darken-1 {
  background-color: #df323b !important;
  border-color: #df323b !important;
}
.v-application .error--text.text--darken-1 {
  color: #df323b !important;
  caret-color: #df323b !important;
}
.v-application .error.darken-2 {
  background-color: #bf0025 !important;
  border-color: #bf0025 !important;
}
.v-application .error--text.text--darken-2 {
  color: #bf0025 !important;
  caret-color: #bf0025 !important;
}
.v-application .error.darken-3 {
  background-color: #9f0010 !important;
  border-color: #9f0010 !important;
}
.v-application .error--text.text--darken-3 {
  color: #9f0010 !important;
  caret-color: #9f0010 !important;
}
.v-application .error.darken-4 {
  background-color: #800000 !important;
  border-color: #800000 !important;
}
.v-application .error--text.text--darken-4 {
  color: #800000 !important;
  caret-color: #800000 !important;
}
.v-application .info {
  background-color: #2196f3 !important;
  border-color: #2196f3 !important;
}
.v-application .info--text {
  color: #2196f3 !important;
  caret-color: #2196f3 !important;
}
.v-application .info.lighten-5 {
  background-color: #d4ffff !important;
  border-color: #d4ffff !important;
}
.v-application .info--text.text--lighten-5 {
  color: #d4ffff !important;
  caret-color: #d4ffff !important;
}
.v-application .info.lighten-4 {
  background-color: #b5ffff !important;
  border-color: #b5ffff !important;
}
.v-application .info--text.text--lighten-4 {
  color: #b5ffff !important;
  caret-color: #b5ffff !important;
}
.v-application .info.lighten-3 {
  background-color: #95e8ff !important;
  border-color: #95e8ff !important;
}
.v-application .info--text.text--lighten-3 {
  color: #95e8ff !important;
  caret-color: #95e8ff !important;
}
.v-application .info.lighten-2 {
  background-color: #75ccff !important;
  border-color: #75ccff !important;
}
.v-application .info--text.text--lighten-2 {
  color: #75ccff !important;
  caret-color: #75ccff !important;
}
.v-application .info.lighten-1 {
  background-color: #51b0ff !important;
  border-color: #51b0ff !important;
}
.v-application .info--text.text--lighten-1 {
  color: #51b0ff !important;
  caret-color: #51b0ff !important;
}
.v-application .info.darken-1 {
  background-color: #007cd6 !important;
  border-color: #007cd6 !important;
}
.v-application .info--text.text--darken-1 {
  color: #007cd6 !important;
  caret-color: #007cd6 !important;
}
.v-application .info.darken-2 {
  background-color: #0064ba !important;
  border-color: #0064ba !important;
}
.v-application .info--text.text--darken-2 {
  color: #0064ba !important;
  caret-color: #0064ba !important;
}
.v-application .info.darken-3 {
  background-color: #004d9f !important;
  border-color: #004d9f !important;
}
.v-application .info--text.text--darken-3 {
  color: #004d9f !important;
  caret-color: #004d9f !important;
}
.v-application .info.darken-4 {
  background-color: #003784 !important;
  border-color: #003784 !important;
}
.v-application .info--text.text--darken-4 {
  color: #003784 !important;
  caret-color: #003784 !important;
}
.v-application .success {
  background-color: #4caf50 !important;
  border-color: #4caf50 !important;
}
.v-application .success--text {
  color: #4caf50 !important;
  caret-color: #4caf50 !important;
}
.v-application .success.lighten-5 {
  background-color: #dcffd6 !important;
  border-color: #dcffd6 !important;
}
.v-application .success--text.text--lighten-5 {
  color: #dcffd6 !important;
  caret-color: #dcffd6 !important;
}
.v-application .success.lighten-4 {
  background-color: #beffba !important;
  border-color: #beffba !important;
}
.v-application .success--text.text--lighten-4 {
  color: #beffba !important;
  caret-color: #beffba !important;
}
.v-application .success.lighten-3 {
  background-color: #a2ff9e !important;
  border-color: #a2ff9e !important;
}
.v-application .success--text.text--lighten-3 {
  color: #a2ff9e !important;
  caret-color: #a2ff9e !important;
}
.v-application .success.lighten-2 {
  background-color: #85e783 !important;
  border-color: #85e783 !important;
}
.v-application .success--text.text--lighten-2 {
  color: #85e783 !important;
  caret-color: #85e783 !important;
}
.v-application .success.lighten-1 {
  background-color: #69cb69 !important;
  border-color: #69cb69 !important;
}
.v-application .success--text.text--lighten-1 {
  color: #69cb69 !important;
  caret-color: #69cb69 !important;
}
.v-application .success.darken-1 {
  background-color: #2d9437 !important;
  border-color: #2d9437 !important;
}
.v-application .success--text.text--darken-1 {
  color: #2d9437 !important;
  caret-color: #2d9437 !important;
}
.v-application .success.darken-2 {
  background-color: #00791e !important;
  border-color: #00791e !important;
}
.v-application .success--text.text--darken-2 {
  color: #00791e !important;
  caret-color: #00791e !important;
}
.v-application .success.darken-3 {
  background-color: #006000 !important;
  border-color: #006000 !important;
}
.v-application .success--text.text--darken-3 {
  color: #006000 !important;
  caret-color: #006000 !important;
}
.v-application .success.darken-4 {
  background-color: #004700 !important;
  border-color: #004700 !important;
}
.v-application .success--text.text--darken-4 {
  color: #004700 !important;
  caret-color: #004700 !important;
}
.v-application .warning {
  background-color: #fb8c00 !important;
  border-color: #fb8c00 !important;
}
.v-application .warning--text {
  color: #fb8c00 !important;
  caret-color: #fb8c00 !important;
}
.v-application .warning.lighten-5 {
  background-color: #ffff9e !important;
  border-color: #ffff9e !important;
}
.v-application .warning--text.text--lighten-5 {
  color: #ffff9e !important;
  caret-color: #ffff9e !important;
}
.v-application .warning.lighten-4 {
  background-color: #fffb82 !important;
  border-color: #fffb82 !important;
}
.v-application .warning--text.text--lighten-4 {
  color: #fffb82 !important;
  caret-color: #fffb82 !important;
}
.v-application .warning.lighten-3 {
  background-color: #ffdf67 !important;
  border-color: #ffdf67 !important;
}
.v-application .warning--text.text--lighten-3 {
  color: #ffdf67 !important;
  caret-color: #ffdf67 !important;
}
.v-application .warning.lighten-2 {
  background-color: #ffc24b !important;
  border-color: #ffc24b !important;
}
.v-application .warning--text.text--lighten-2 {
  color: #ffc24b !important;
  caret-color: #ffc24b !important;
}
.v-application .warning.lighten-1 {
  background-color: #ffa72d !important;
  border-color: #ffa72d !important;
}
.v-application .warning--text.text--lighten-1 {
  color: #ffa72d !important;
  caret-color: #ffa72d !important;
}
.v-application .warning.darken-1 {
  background-color: #db7200 !important;
  border-color: #db7200 !important;
}
.v-application .warning--text.text--darken-1 {
  color: #db7200 !important;
  caret-color: #db7200 !important;
}
.v-application .warning.darken-2 {
  background-color: #bb5900 !important;
  border-color: #bb5900 !important;
}
.v-application .warning--text.text--darken-2 {
  color: #bb5900 !important;
  caret-color: #bb5900 !important;
}
.v-application .warning.darken-3 {
  background-color: #9d4000 !important;
  border-color: #9d4000 !important;
}
.v-application .warning--text.text--darken-3 {
  color: #9d4000 !important;
  caret-color: #9d4000 !important;
}
.v-application .warning.darken-4 {
  background-color: #802700 !important;
  border-color: #802700 !important;
}
.v-application .warning--text.text--darken-4 {
  color: #802700 !important;
  caret-color: #802700 !important;
}
</style>
