<template>
  <div class="sidebar" :class="{ 'sidebarActive' : showSidebar }">
    <div class="sidebar_trigger" :style="sidebarButtonTopMargin" @mouseover="sidebarHover">
        <div v-for="(item,index) in sidebarContent" :key="index+'a'" class="sidebarButtonShadow" :style="item.styles" :class="{ 'active' : activeSidebar != item.name }"></div>
        <div v-for="(item,index) in sidebarContent" :key="index+'b'" class="sidebarButton" :class="[item.cssClass, { 'sidebarButtonActive' : activeSidebar == item.name }]" @click="handleSidebar(item.name, item.description, item.introText)" :style="item.styles">
            <div class="icon"></div>
            <div class="description" :style="item.descStyles">{{ item.description }}</div>
            <span v-if="item.name == 'Favourites' && item.total_favs != 0" v-html="item.total_favs"></span>
        </div>
        <div class="sidebar_hint" :class="{ 'active' : activeSidebarHint }">Kennen Sie unser <br><b>Servicemenü</b> schon?</div>
    </div>
    <div class="sidebar_content">
        <div class="activeElMarkerHolder" :style="sidebarButtonTopMargin">
            <div v-for="(item,index) in sidebarContent" :key="index" :style="item.activeMarkerPosition" class="activeElMarker" :class="{ 'activeElMarkerActive' : activeSidebar == item.name }"></div>
        </div>
        <div class="closeSidebarWrapper">
            <div class="closeSidebar" @click="closeSidebar">Schliessen
              <div class="close"></div>
            </div>
        </div>
        <div ref="contentWrapper" class="contentWrapper" @scroll="handleContentScroll()">
            <span class="contentHl">{{ sidebarHeadline }}</span>
            <p>{{ sidebarIntro }}</p>
            <div ref="contentWrapperInner" class="contentWrapperInner">
                <transition name="fade">
                    <Search ref="searchComponent" v-if="activeSidebar=='Search'"/>
                    <Contacts @count_total="get_total_favs" :contactsData="contacts" :searchedContactData="searchedContact" ref="Contacts" v-if="activeSidebar=='Contacts'"/>
                    <OpeningHours :openingsData="openings" v-if="activeSidebar=='OpeningHours'"/>
<!--                    <Trash v-if="activeSidebar=='Trash'"/>-->
                    <NewTrashSnippet v-if="activeSidebar=='Trash'"/>
                    <KFZ @closeSideBar="closeSidebar" v-if="activeSidebar=='KFZ'"/>
                    <Favourites @count_total="get_total_favs" :contactsData="contacts" v-if="activeSidebar=='Favourites'"/>
                </transition>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
    import axios from "axios";
  import Search from './sidebarComponents/Search.vue'
  import Contacts from './sidebarComponents/Contacts.vue'
  import OpeningHours from './sidebarComponents/OpeningHours.vue'
  // import Trash from './sidebarComponents/Trash.vue'
  import NewTrashSnippet from './sidebarComponents/NewTrashSnippet.vue'
  import KFZ from './sidebarComponents/KFZ.vue'
  import Favourites from './sidebarComponents/Favourites.vue'

  export default {
    name: "Sidebar",
    components: {
        Search,
        Contacts,
        OpeningHours,
        // Trash,
        NewTrashSnippet,
        KFZ,
        Favourites
    },
    data: () => ({
        lastScrollTop: 0,
        scrollTop: 0,
        searchedContact: null,
        showSidebar: false,
        activeSidebar: false,
        activeSidebarHint: false,
        sidebarHeadline: false,
        sidebarIntro: false,
        sidebarButtonTopMargin: {marginTop: '145px'},
        sidebarContent: [
            {name: 'Search', description: 'Suche', cssClass: "sidebarButtonSearch", styles: {top: '0px', animationDelay: '0.5s'}, descStyles: {}, activeMarkerPosition: {top: '0px'}, introText: "Geben Sie Ihren gewünschten Suchbegriff in das Suchfeld ein, nach dem Sie die Seite durchsuchen wollen."},
            {name: 'Contacts', description: 'Telefon- und Kontaktverzeichnis', cssClass: "sidebarButtonContact", styles: {top: '65px', animationDelay: '0.55s'}, descStyles: {lineHeight: '20px', height: '40px', bottom: '0px', margin: 'auto'}, activeMarkerPosition: {top: '65px'}, introText: "Hier können Sie Telefonnummern und Adressdaten der Ansprechpartner anzeigen lassen. Sie können nach ganzen Abteilungen oder konkreten Personen suchen. Mit Hilfe eines Suchbegriffs können Sie sowohl alle Abteilungen als auch alle Ansprechpartner durchsuchen."},
            {name: 'OpeningHours', description: 'Öffnungszeiten', cssClass: "sidebarButtonOpeningHours", styles: {top: '130px', animationDelay: '0.6s'}, descStyles: {}, activeMarkerPosition: {top: '130px'}, introText: "Hier sehen Sie die Öffnungszeiten aller Abteilungen des Landratsamtes Rhön-Grabfeld."},
            {name: 'KFZ', description: 'KFZ', cssClass: "sidebarButtonKFZ", styles: {top: '195px', animationDelay: '0.65s'}, descStyles: {}, activeMarkerPosition: {top: '195px'}, introText: "Mit Hilfe der Schnellzugriffe der wichtigsten Dienste im Bereich KFZ gelangen Sie direkt zum gewünschten Onlinedienst."},
            {name: 'Trash', description: 'Abfuhr-Kalender', cssClass: "sidebarButtonTrash", styles: {top: '260px', animationDelay: '0.7s'}, descStyles: {}, activeMarkerPosition: {top: '260px'}, introText: "Der Abfuhr-Kalender bietet für Sie individuell abrufbar die Entsorgungstermine für Restmüll, Gelbe Tonne, Biotonne, Papiersammlung, Problemmüllsammlung. Ebenso erhalten Sie hier die Öffnungszeiten mit Routenplanung für die Annahme am Wertstoffzentrum und den Wertstoffhöfen."},
            {name: 'Favourites', description: 'Gemerkte Elemente', cssClass: "sidebarButtonFavourites", styles: {top: '325px', animationDelay: '0.75s'}, descStyles: {}, activeMarkerPosition: {top: '325px'}, introText: "Hier stehen Ihnen Ihre gemerkten Ansprechpartner, Seiten und Veranstaltungen zur Verfügung. Gemerkte Elemente können Sie hier auch wieder löschen.", total_favs: 0}

        ],
        /*
        contacts: [
            {id: 0, pids:'1;2;3', vorname: "", nachname: "", info: "Hier können weitere Informationen stehen", telefon: "1233456", fax: "adfadf", zimmer: "222", mail: "max.mustermann@lkr.de", abteilung: "Abteilung XY", suchbegriffe: "test, test2", person_abteilung: "Abteilung", bildlink: "www.google.de", is_favourite: false},
            {id: 1, pids:'3', vorname: "Tugay", nachname: "Aydin", info: "Hier können weitere Informationen stehen", telefon: "1233456", fax: "adfadf", zimmer: "222", mail: "max.mustermann@lkr.de", abteilung: "abteilung1", suchbegriffe: "test, test2", person_abteilung: "Person", bildlink: "www.google.de", is_favourite: false},
            {id: 2, pids:'3', vorname: "Lukas", nachname: "Frank", info: "Hier können weitere Informationen stehen", telefon: "1233456", fax: "adfadf", zimmer: "222", mail: "max.mustermann@lkr.de", abteilung: "abteilung1", suchbegriffe: "test, test2", person_abteilung: "Person", bildlink: "www.google.de", is_favourite: false},
            {id: 3, pids:'1', vorname: "Christian", nachname: "Volk", info: "Hier können weitere Informationen stehen", telefon: "1233456", fax: "adfadf", zimmer: "222", mail: "max.mustermann@lkr.de", abteilung: "abteilung1", suchbegriffe: "test, test2", person_abteilung: "Person", bildlink: "www.google.de", is_favourite: false},
            {id: 4, pids:'3', vorname: "Lena", nachname: "Lugert", info: "Hier können weitere Informationen stehen", telefon: "1233456", fax: "adfadf", zimmer: "222", mail: "max.mustermann@lkr.de", abteilung: "abteilung1", suchbegriffe: "test, test2", person_abteilung: "Person", bildlink: "www.google.de", is_favourite: false},
            {id: 5, pids:null, vorname: "Sarah", nachname: "Gerstner", info: "Hier können weitere Informationen stehen", telefon: "1233456", fax: "adfadf", zimmer: "222", mail: "max.mustermann@lkr.de", abteilung: "abteilung1", suchbegriffe: "test, test2", person_abteilung: "Person", bildlink: "www.google.de", is_favourite: false},
            {id: 6, pids:null, vorname: "Sophia", nachname: "Hassmann", info: "Hier können weitere Informationen stehen", telefon: "1233456", fax: "adfadf", zimmer: "222", mail: "max.mustermann@lkr.de", abteilung: "abteilung1", suchbegriffe: "test, test2", person_abteilung: "Person", bildlink: "www.google.de", is_favourite: false},
            {id: 7, pids:null, vorname: "Paula", nachname: "Dietz", info: "Hier können weitere Informationen stehen", telefon: "1233456", fax: "adfadf", zimmer: "222", mail: "max.mustermann@lkr.de", abteilung: "abteilung1", suchbegriffe: "test, test2", person_abteilung: "Person", bildlink: "www.google.de", is_favourite: false},
            {id: 8, pids:null, vorname: "Julius", nachname: "Hauck", info: "Hier können weitere Informationen stehen", telefon: "1233456", fax: "adfadf", zimmer: "222", mail: "max.mustermann@lkr.de", abteilung: "abteilung1", suchbegriffe: "test, test2", person_abteilung: "Person", bildlink: "www.google.de", is_favourite: false},
            {id: 9, pids:null, vorname: "Kristina", nachname: "Freibott", info: "Hier können weitere Informationen stehen", telefon: "1233456", fax: "adfadf", zimmer: "222", mail: "max.mustermann@lkr.de", abteilung: "abteilung1", suchbegriffe: "test, test2", person_abteilung: "Person", bildlink: "www.google.de", is_favourite: false},
            {id: 10, pids:null, vorname: "Marcel", nachname: "Reyer", info: "Hier können weitere Informationen stehen", telefon: "1233456", fax: "adfadf", zimmer: "222", mail: "max.mustermann@lkr.de", abteilung: "abteilung1", suchbegriffe: "test, test2", person_abteilung: "Person", bildlink: "www.google.de", is_favourite: false},
            {id: 11, pids:null, vorname: "", nachname: "", info: "Hier können weitere Informationen stehen", telefon: "1233456", fax: "adfadf", zimmer: "222", mail: "max.mustermann@lkr.de", abteilung: "abteilung1", suchbegriffe: "test, test2", person_abteilung: "Abteilung", bildlink: "www.google.de", is_favourite: false}
        ]
         */
        contacts: [],
        openings: []
    }),
    methods: {
        get_contacts() {
            axios
                // .get('https://fs-api-rg.offizium.com/website/contacts')
                // LIVE
                .get('https://aht1gh-api.sqronline.de/lkr/website/contacts')
                // DEV
                // .get('http://api.hallogemeinde.local/lkr/website/contacts')
                .then(response => {
                    let contacts = response.data;
                    for(var i = 0; i < contacts.length; i++) {
                        contacts[i].is_favourite = false;
                    }
                    this.contacts = contacts;
                    this.check_if_all_fav_contacts_still_exist(contacts);
                })
                .catch(error => {
                    // eslint-disable-next-line no-console
                    console.error(error);
                });
        },
        get_openings() {
            axios
                // .get('https://fs-api-rg.offizium.com/website/openingtimes')
                // LIVE
                .get('https://aht1gh-api.sqronline.de/lkr/website/openingtimes')
                // DEV
                // .get('http://api.hallogemeinde.local/lkr/website/openingtimes')
                .then(response => {
                    let openings = response.data;
                    for(var i = 0; i < openings.length; i++) {
                        openings[i].active = false;
                    }
                    this.openings = openings;
                })
                .catch(error => {
                    // eslint-disable-next-line no-console
                    console.error(error);
                });
        },
        handleSidebarFromOutsideVue(index, filter, searchName) {
          // eslint-disable-next-line no-console
          // console.log('searchName', searchName)
          // eslint-disable-next-line no-console
          // console.log('this.$refs', this.$refs)
          // eslint-disable-next-line no-console
          // console.log('this.sidebarContent', this.sidebarContent)
            if ( searchName !== '') {
              // this.$refs.Contacts.setSearchedName(searchName) // this.$refs.Contacts is undefined, because it is in <transition> amd mot always rendered // -> set searchName and activeFilter (like in Contacts.vue method setSearchedName) in localStorage; due to transition mounted of Contacts.vue is run everytime the 'Contacts'-sidebar is opened
              localStorage.contactSearch = searchName
              localStorage.contactFilter = 'Personen'
            }
            let sName = this.sidebarContent[index].name;
            let sDescription = this.sidebarContent[index].description;
            let sIntro = this.sidebarContent[index].introText;
            this.handleSidebar(sName, sDescription, sIntro);
        },
        check_if_all_fav_contacts_still_exist(contacts) {
            var fav_contacts = localStorage.getItem("favourite_contacts");
            var new_fav_contacts = [];
            if(fav_contacts) {
                fav_contacts = fav_contacts.split(";");
                for(var i=0; i<fav_contacts.length; i++) {
                    let fav_id = fav_contacts[i];
                    for(var j=0; j < contacts.length; j++) {
                        let contact_id = contacts[j].id;
                        if(fav_id == contact_id) {
                            new_fav_contacts.push(fav_id);
                        }
                    }
                }
            }
            new_fav_contacts = new_fav_contacts.join(";");
            localStorage.setItem("favourite_contacts", new_fav_contacts);
            this.get_total_favs();
        },
        get_total_favs() {
            var total = 0;
            var fav_contacts = localStorage.getItem("favourite_contacts");
            if(fav_contacts) {
                fav_contacts = fav_contacts.split(";");
                total += fav_contacts.length;
            }
            let fav_events = localStorage.getItem("favorite_events");
            if(fav_events) {
                let fav_events_arr = fav_events.split(",");
                total += fav_events_arr.length;
                // for(i=0; i < fav_events_arr.length; i++) {
                //     fav_events_arr[i] = parseInt(fav_events_arr[i]);
                // }
                fav_events_arr = JSON.stringify(fav_events_arr);
                // eslint-disable-next-line no-console
                //console.log(fav_events);
                axios({
                    method: 'post',
                    // url: 'https://fs-api-rg.offizium.com/events/someevents',
                  // LIVE
                    url: 'https://aht1gh-api.sqronline.de/api/modules/events/getevents-post',
                  // DEV
                    // url: 'http://api.hallogemeinde.local/api/modules/events/getevents-post',
                    data: {
                        idArray: fav_events_arr
                    }
                }).then((response) => {
                    let data = response.data;
                    if(data) {
                        var new_event_string = "";
                        for(i=0; i < data.length; i++) {
                            // new_event_string += data[i].id;
                            new_event_string += data[i].uuid;
                            if(data.length - i > 1) {
                                new_event_string += ",";
                            }
                        }
                        if(new_event_string != fav_events) {
                            this.get_total_favs();
                        }
                        localStorage.setItem("favorite_events", new_event_string);
                    }
                });
            }
            var fav_pages = localStorage.getItem("fav_pages");
            if(fav_pages) {
                fav_pages = JSON.parse(fav_pages);
                total += fav_pages.length
            }
            var fav_downloads = localStorage.getItem("fav_downloads");
            if(fav_downloads) {
                fav_downloads = JSON.parse(fav_downloads);
                total += fav_downloads.length
            }
            for(var i=0; i < this.sidebarContent.length; i++) {
                if(this.sidebarContent[i].name == "Favourites") {
                    this.sidebarContent[i].total_favs = total;
                }
            }
        },
      handleSidebar(sName, sDescription, sIntro) {
          this.sidebarHeadline = sDescription
          this.sidebarIntro = sIntro
          var body = document.getElementsByTagName("BODY")[0];
        if(this.activeSidebar == sName) {
          this.activeSidebar = false
          this.showSidebar = false
            body.classList.remove("no_scroll");
        } else {
          this.activeSidebar = sName
          this.showSidebar = true
            body.classList.add("no_scroll");
        }
        this.showOverlay()
      },
      showOverlay() {
        this.$emit('clickedSidebar', this.showSidebar)
      },
      closeSidebar() {
        this.activeSidebar = false
        this.showSidebar = false
        this.$emit('clickedSidebar', this.showSidebar)
          var body = document.getElementsByTagName("BODY")[0];
          body.classList.remove("no_scroll");
      },
      handleContentScroll() {
        if(this.$refs.Contacts) {
            this.scrollTop = this.$refs.contentWrapper.scrollTop;
            if(this.scrollTop < this.lastScrollTop) {
                this.$refs.Contacts.handleScroll(this.$refs.contentWrapperInner.clientWidth,this.scrollTop,'up');
            } else {
                this.$refs.Contacts.handleScroll(this.$refs.contentWrapperInner.clientWidth,this.scrollTop,'down');
            }
            this.lastScrollTop = this.scrollTop
        }
      },
      sidebarHover() {
          // console.log('in sidebarHover')
        this.hideSidebarHint()
      },
      hideSidebarHint() {
          this.activeSidebarHint = false
      },
      showSidebarHint() {
          this.activeSidebarHint = true
      },
      /*
        method to check if storage (localStorage) is available to use
       */
      storageAvailable (type) {
        let storage;
        try {
          storage = window[type];
          const x = '__storage_test__';
          storage.setItem(x, x);
          storage.removeItem(x);
          return true;
        }
        catch(e) {
          return e instanceof DOMException && (
                  // everything except Firefox
                  e.code === 22 ||
                  // Firefox
                  e.code === 1014 ||
                  // test name field too, because code might not be present
                  // everything except Firefox
                  e.name === 'QuotaExceededError' ||
                  // Firefox
                  e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
              // acknowledge QuotaExceededError only if there's something already stored
              (storage && storage.length !== 0);
        }
      },
      checkSidebarHintTimestamp() {
        if (this.storageAvailable('localStorage')) {
          // Yippee! We can use localStorage awesomeness
          let sidebarHintTimestamp = localStorage.getItem('sidebarHintTimestamp') // get the data from localStorage
          if (sidebarHintTimestamp) {
            // check if timestamp is not older than 1/4 year (sidebar hint needs not to be shown again then)
            let sidebarHintTime = new Date(parseInt(sidebarHintTimestamp))
            // console.log(sidebarHintTime) // eslint-disable-line
            if (sidebarHintTime !== 'Invalid Date') {
              let sidebarHintTimeInThreeMonths = sidebarHintTime
              sidebarHintTimeInThreeMonths.setMonth(sidebarHintTimeInThreeMonths.getMonth() + 3)
              let now = new Date();
              // eslint-disable-next-line
              // console.log(sidebarHintTimeInThreeMonths.getTime(), now.getTime())
              if (sidebarHintTimeInThreeMonths.getTime() > now.getTime()) {
                // sidebarHintTimestamp is younger than 1/4 year -> do not show hint
                /* eslint-disable */
                // console.log('sidebarHintTimestamp is younger than 1/4 year')
                /* eslint-enable */
                this.hideSidebarHint()
              } else {
                // sidebarHint is older than 1/4 year -> set sidebarHintTimestamp and show hint again
                // eslint-disable-next-line
                // console.log('sidebarHint is older than 1/4 year') // eslint-disable-line
                this.setSidebarHintTimestamp()
                this.showSidebarHint()
              }
            } else { // 'Invalid Date' for sidebarHintTimestamp
              this.setSidebarHintTimestamp()
              this.showSidebarHint()
            }
          } else {
            // no sidebarHintTimestamp set
            this.setSidebarHintTimestamp()
            this.showSidebarHint()
          }
        }
        else {
          // Too bad, no localStorage for us
        }
      },
      setSidebarHintTimestamp() {
        if (this.storageAvailable('localStorage')) {
          // Yippee! We can use localStorage awesomeness
          let now = new Date();
          localStorage.setItem('sidebarHintTimestamp', now.getTime())
        }
        else {
          // Too bad, no localStorage for us
        }
      },
      removeSidebarHintAfterSeconds(seconds = 20) {
        setTimeout(() => {
          this.hideSidebarHint()
        }, seconds*1000)
      }
    },
      mounted() {
        this.get_contacts();
        this.get_openings();
        this.get_total_favs();
        this.checkSidebarHintTimestamp();
        this.removeSidebarHintAfterSeconds(20);
      }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    @import url(../assets/css/style.scss);
  .fade-enter-active {
    transition: opacity .5s;
  }
  .fade-leave-active {
      transition: none;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
  .sidebar {
    font-family: 'Open Sans', Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 710px;
      width: 650px;
    position: fixed;
    top: 0px;
      bottom: 0px;
    right: -650px;
      z-index: 9999;
    transition: all 500ms cubic-bezier(0.400, 0.275, 0.120, 0.945);
  }
  .sidebarActive {
    right: 0px;
  }
  .sidebar_trigger {
    width: 60px;
    position: relative;
  }
  .activeElMarkerHolder {
    position: absolute;
  }
  .sidebar_hint {
    position: absolute;
    left:0px;
    top:30px;
    background-color: #00862f;
    padding: 10px 20px 10px 15px;
    color:white;
    opacity: 0;
    transition: all 1s ease;
  }
  .sidebar_hint:after {
    content: "";
    position: absolute;
    right: -15px;
    top:0;
    width: 0;
    height: 0;
    border-right: 15px solid transparent;
    border-top: 15px solid #00862f;
    border-bottom: 0 solid transparent;
  }
  .sidebar_hint.active {
    left:-280px;
    /*opacity: 1;*/
    /*animation: slideInFromRight;*/
    /*animation-fill-mode: both;*/
    /*animation-duration: .2s;*/
    /*animation-delay: 1s;*/
    animation: fadeIn;
    animation-fill-mode: both;
    animation-duration: .2s;
    animation-delay: 1s;
  }
    @keyframes slideInFromRight {
      0% {
        left: 0px;
      }
      100% {
        left: -280px;
      }
    }
    @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity:1;
      }
    }
  .sidebar_content {
    box-sizing: border-box;
    width: 650px;
    height: 100%;
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 1;
    background: #fff;
    border-left: 4px solid #008AD7;
  }
  .activeElMarker {
      content: "";
      position: absolute;
      margin-top: 45px;
      left: 0px;
      width: 0;
      height: 0;
      opacity: 0;
      border-right: 15px solid transparent;
      border-top: 0px solid transparent;
      border-bottom: 15px solid #008ad7;
      transition: opacity 0.3s ease;
      z-index: 9;
  }
  .activeElMarkerActive {
      opacity: 1;
  }
  .sidebarButton {
    height: 60px;
    background: #fff;
    margin-bottom: 5px;
    cursor: pointer;
    width: 220px;
    box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.4);
    transition: all 0.3s ease;
    animation: slideInFromLeft;
    animation-fill-mode: both;
    animation-duration: .2s;
    animation-delay: 0.5s;
    position: absolute;
    left:-60px;
  }
  .sidebarButton .description {
    height: 60px;
    width: 160px;
    overflow: hidden;
    position: absolute;
    right: 0px;
    top: 0px;
    line-height: 60px;
    color: #fff !important;
    font-size: 16px;
  }
  .sidebarButton .icon {
    height: 60px;
    width: 60px;
    float: left;
    transition: all 0.2s ease;
  }
  .sidebarButtonSearch .icon {
      background: url(../assets/images/search.svg) center center no-repeat;
  }
  .sidebarButton span {
      height: 26px;
      width: 26px;
      background: #008ad7;;
      color: #fff !important;
      position: absolute;
      left: -10px;
      border-radius: 50%;
      text-align: center;
      line-height: 26px;
      font-size: 13px;
      bottom: -10px;
      box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.4);
  }
  .sidebarButton .icon {
    background-repeat: no-repeat;
    background-position: center;
  }
  .sidebarButtonSearch:hover .icon, .sidebarButtonSearch.sidebarButtonActive .icon {
    background-image: url(../assets/images/search_white.svg);
  }
  .sidebarButtonContact .icon {
    background-image: url(../assets/images/phone.svg);
  }
  .sidebarButtonContact:hover .icon, .sidebarButtonContact.sidebarButtonActive .icon {
    background-image: url(../assets/images/phone_white.svg);
  }
  .sidebarButtonOpeningHours .icon {
    background-image: url(../assets/images/time.svg);
  }
  .sidebarButtonOpeningHours:hover .icon, .sidebarButtonOpeningHours.sidebarButtonActive .icon {
    background-image: url(../assets/images/time_white.svg);
  }
  .sidebarButtonTrash .icon {
    background-image: url(../assets/images/trash.svg);
  }
  .sidebarButtonTrash:hover .icon, .sidebarButtonTrash.sidebarButtonActive .icon {
    background-image: url(../assets/images/trash_white.svg);
  }
  .sidebarButtonFavourites .icon {
    background-image: url(../assets/images/fav.svg);
  }
  .sidebarButtonFavourites:hover .icon, .sidebarButtonFavourites.sidebarButtonActive .icon {
    background-image: url(../assets/images/fav_white.svg);
  }
    .sidebarButtonKFZ .icon {
        background-image: url(../assets/images/kfz.svg);
    }
    .sidebarButtonKFZ:hover .icon, .sidebarButtonKFZ.sidebarButtonActive .icon {
        background-image: url(../assets/images/kfz_white.svg);
    }
  .sidebarButton:hover, .sidebarButtonActive {
    background: #008AD7;
  }
  .sidebarButton:hover {
    left: -220px;
  }
  .closeSidebarWrapper {
    height: 80px;
    position: relative;
    background: #fff;
    z-index: 9;
    border-bottom: 3px solid #E2E2E2;
  }
  .closeSidebar {
    height: 36px;
    line-height: 36px;
    font-size: 16px;
    text-transform: uppercase;
    color: #484848;
    padding-left: 30px;
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 40px;
    margin: auto;
    cursor: pointer;
      transition: all 0.3s ease-in-out;
  }
    .closeSidebar:hover {
        color: #000;
    }
    .closeSidebar::after {
    content:"";
    height: 2px;
    width: 100%;
    position: absolute;
    left: 0px;
    bottom: 4px;
    background: #008AD7;
        transition: all 0.3s ease-in-out;
  }
    .closeSidebar:hover::after {
        transform: scaleX(1.2);
    }
  .divider {
    height: 3px;
    width: 100%;
    background: #E2E2E2;
  }
  .close {
    position: absolute;
    left: 0px;
    top: 0px;
    bottom: 0px;
    margin: auto;
    width: 28px;
    height: 28px;
  }
  .close:before, .close:after {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    content: ' ';
    height: 14px;
    width: 2px;
    background-color: #008AD7;
    margin: auto;
      transition: all 0.1s ease-in-out;
  }
  .close:before {
    transform: rotate(45deg);
  }
  .close:after {
    transform: rotate(-45deg);
  }

  .sidebarButtonShadow {
      display: none;
      height: 60px;
      width: 10px;
      box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.8);
      position: absolute;
      right: -10px;
      z-index: 1;
  }
  .sidebarActive .sidebarButtonShadow.active {
      display: block;
  }
  .contentWrapper {
      position: absolute;
      top: 83px;
      bottom:0px;
      left: 0px;
      right: 0px;
      padding: 60px;
      color: #484848;
      overflow: auto;
    display: flex;
    width: 100%;
    height: calc(100% - 60px);
    flex-direction: column;
  }
    .contentWrapperInner {
      padding-top: 30px;
      display: flex;
      width: 100%;
      height: calc(100% - 30px);
      flex-direction: column;
    }
  .contentWrapper .contentHl {
      font-size: 20px;
      text-transform: uppercase;
  }
  .contentWrapper p {
      font-size: 14px;
      line-height: 26px;
  }
    @keyframes slideInFromLeft {
        0% {
          transform: translateX(110%);
        }
        100% {
          transform: translateX(0);
        }
    }
    @media only screen and (max-width: 1330px) {
      .sidebarButton {
        width: 196px;
        left: -36px;
      }
      .sidebarButton:hover {
        left: -196px;
      }
      .sidebarButton .icon {
        width: 36px;
        background-size: 22px;
      }
      .sidebar_hint.active {
        left: -255px;
      }
    }
    @media only screen and (max-width: 880px) {
        .sidebarActive .sidebarButton:hover {
            left: -36px;
        }
    }
    @media only screen and (max-width: 720px) {
        .sidebarActive {
            width: 100%;
        }
        .sidebarActive .sidebar_content {
            width: 100%;
            border: none;
        }
        .sidebarActive .sidebar_content .activeElMarker {
            display: none;
        }
        .contentWrapper {
            padding: 20px;
        }
        .closeSidebar {
            left: 20px;
        }
    }
</style>
