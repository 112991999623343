import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)

const vuetify_options = {
    icons: {
        iconfont: 'mdiSvg'
    },
    theme: { disable: true }
}

export default new Vuetify(vuetify_options)