<template>
    <div>
        <form method="get" id="form_kesearch_pi1" name="form_kesearch_pi1" action="/suche">
            <input type="hidden" name="id" value="182">
            <input v-model="search_value" type="text" name="tx_kesearch_pi1[sword]" value="" placeholder="Suchbegriff eingeben...">
            <input id="submit" type="submit" value="" :disabled="search_value == ''" :class="{ 'disabled' : search_value == '' }">
        </form>
    </div>
</template>

<script>
    export default {
        name: "Search",
        data: () => ({
            search_value: ""
        })
    }
</script>

<style scoped>
    form {
        position: relative;
    }
    input {
        width: 100%;
        height: 40px;
        line-height: 40px;
        padding-left: 15px;
        padding-right: 5px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        font-size: 14px;
        font-family: 'Open Sans', Arial, sans-serif;
        outline: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border: 1px solid #BEBEBE;
    }
    input#submit {
        position: absolute;
        width: 50px;
        top: 0px;
        right: 0px;
        background-color: #00862F;
        background-image: url(../../assets/images/search_white.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 25px;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        border: none;
        padding: 0px;
    }
    input#submit.disabled {
        opacity: 0.3;
        cursor: not-allowed;
    }
</style>