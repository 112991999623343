<template>
    <div>
        <div v-for="(item,index) in contactData_computed" v-bind:key="index" class="contact_person_wrapper" :class="{ 'first_divider' : item.divider_first }">
            <div v-if="item.person_abteilung.toLowerCase() == 'person' || item.person_abteilung.toLowerCase() == 'abteilung'">
                <div @click="addToFavourites(item.id)" class="contact_to_favourites" :class="{ 'active' : item.is_favourite }">{{ item.is_favourite ? 'Entfernen' : 'Merken' }}</div>
                <div v-if="item.url" class="contact_pic"><img :src="item.url"></div>
                <div class="contact_info" :class="{ 'no_pic' : !item.url }">
                    <p v-if="item.person_abteilung.toLowerCase() == 'person'" class="contact_name"><span v-if="item.anrede">{{ item.anrede }} </span><span v-if="item.titel">{{ item.titel }} </span><span v-if="item.nachname">{{ item.nachname }} </span><span v-if="item.vorname">{{ item.vorname }} </span></p>
                    <p v-if="item.person_abteilung.toLowerCase() == 'abteilung'" class="contact_name">{{ item.abteilung }}</p>
                    <div class="contact_text" v-html="item.info"></div>
                    <div class="margin_contacts"></div>
                    <p v-if="item.person_abteilung.toLowerCase() == 'person'" v-html="'<strong>'+item.abteilung+'</strong>'"></p>
                    <p v-if="item.telefonlink && item.telefon">Telefon: <a v-bind:href="'tel:' + item.telefonlink">{{ item.telefon }}</a></p>
                    <p v-if="!item.telefonlink && item.telefon">Telefon: {{ item.telefon }}</p>
                    <p v-if="item.fax">Telefax: {{ item.fax }}</p>
                    <p v-if="item.zimmer">Zimmer: {{ item.zimmer }}</p>
                    <p v-if="item.email">E-Mail: <a v-bind:href="'mailto:' + item.email">{{ item.email }}</a></p>
                </div>
                <div class="clearfix"></div>
            </div>
            <div v-if="item.person_abteilung == 'divider'">
                <span class="more_contacts">{{item.divider_text}}</span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Contact",
        props: {
            contactData: Array,
            active_el: String
        },
        computed: {
          contactData_computed() {
            var contact_data = this.contactData;
            for(var i=0; i < contact_data.length; i++) {
              let telefon = contact_data[i].telefon;
              if(telefon) {
                while(telefon.includes(" ")) {
                  telefon = telefon.replace(" ","");
                }
                while(telefon.includes("-")) {
                  telefon = telefon.replace("-","");
                }
                while(telefon.includes("/")) {
                  telefon = telefon.replace("/","");
                }
                contact_data[i].telefonlink = telefon;
              }
              if(contact_data[i].person_abteilung && contact_data[i].person_abteilung.toLowerCase() == 'person') {
                let abteilung = contact_data[i].abteilung;
                if(abteilung) {
                  while(abteilung.includes(";")) {
                    abteilung = abteilung.replace(";","<br>");
                  }
                  contact_data[i].abteilung = abteilung;
                }
              }
              if(contact_data[i].image && contact_data[i].image[0] && contact_data[i].image[0].url) {
                let pic_link = contact_data[i].image[0].url
                // url is like https://files1.firstspace.de/files/6926481787 -> get thumbnail with https://files1.firstspace.de/files/thumbnail/6926481787?thumbsize=x680
                let lastSlashIndex = pic_link.lastIndexOf('/')
                if (lastSlashIndex !== -1) {
                  let thumbnailUrl = pic_link.substring(0, lastSlashIndex) + '/thumbnail' + pic_link.substring(lastSlashIndex) + '?thumbsize=x680'
                  contact_data[i].url = thumbnailUrl
                }
              }
            }

            function compare(a, b) {
              if(a.person_abteilung.toLowerCase() == 'abteilung') {
                var wert1 = a.abteilung.toLowerCase().replace(/\u00e4/g, "ae");
                wert1 = wert1.replace(/\u00fc/g, "ue");
                wert1 = wert1.replace(/\u00f6/g, "oe");

                var wert2 = b.abteilung.toLowerCase().replace(/\u00e4/g, "ae");
                wert2 = wert2.replace(/\u00fc/g, "ue");
                wert2 = wert2.replace(/\u00f6/g, "oe");

                if (wert1 < wert2)
                  return -1;
                if (wert1 > wert2)
                  return 1;
                return 0;
              } else {
                wert1 = a.nachname+a.vorname;
                wert1 = wert1.toLowerCase().replace(/\u00e4/g, "ae");
                wert1 = wert1.replace(/\u00fc/g, "ue");
                wert1 = wert1.replace(/\u00f6/g, "oe");

                wert2 = b.nachname+b.vorname;
                wert2 = wert2.toLowerCase().replace(/\u00e4/g, "ae");
                wert2 = wert2.replace(/\u00fc/g, "ue");
                wert2 = wert2.replace(/\u00f6/g, "oe");

                if (wert1 < wert2)
                  return -1;
                if (wert1 > wert2)
                  return 1;
                return 0;
              }
            }
            contact_data.sort(compare);
            // eslint-disable-next-line no-console
            // console.log('contact_data after sorting in Contact', contact_data)
            // To sort specific contacts to first position, there could be a star (*) used for name -> remove this star after sorting
            for (var r = 0, len = contact_data.length; r < len; r++) {
              if (contact_data[r].abteilung && contact_data[r].abteilung.startsWith('*')) {
                contact_data[r].abteilung = contact_data[r].abteilung.replace('*', '')
              }
              if (contact_data[r].nachname && contact_data[r].nachname.startsWith('*')) {
                contact_data[r].nachname = contact_data[r].nachname.replace('*', '')
              }
              if (contact_data[r].vorname && contact_data[r].vorname.startsWith('*')) {
                contact_data[r].vorname = contact_data[r].vorname.replace('*', '')
              }
            }
            let page_id = document.getElementById("page_id").getAttribute("page_id");
            if(page_id) {
              var contacts_by_page_id = [];
              var default_contacts = [];
              for(i = 0; i < contact_data.length; i++) {
                  let contact_pids = contact_data[i].page;
                  if(contact_pids && contact_pids.length > 0) {
                      var is_contact_by_pid = false;
                      for(var j = 0; j < contact_pids.length; j++) {
                          if(contact_pids[j].page_id == page_id) {
                              is_contact_by_pid = true;
                          }
                      }
                      if(is_contact_by_pid) {
                          contacts_by_page_id.push(contact_data[i]);
                      } else {
                          default_contacts.push(contact_data[i]);
                      }
                  } else {
                      default_contacts.push(contact_data[i]);
                  }
              }

              if(contacts_by_page_id.length !== 0) {
                  var return_contacts = [];
                  let divider_element = {"person_abteilung": "divider", "divider_text": "Kontakte für diesen Bereich", "divider_first": true}
                  return_contacts.push(divider_element);
                  for(i=0; i < contacts_by_page_id.length; i++) {
                    return_contacts.push(contacts_by_page_id[i]);
                  }
                  if(default_contacts.length > 0) {
                    let divider_element_2 = {"person_abteilung": "divider", "divider_text": "Weitere Kontakte"}
                    return_contacts.push(divider_element_2);
                    for(i=0; i < default_contacts.length; i++) {
                      return_contacts.push(default_contacts[i]);
                    }
                  }
                  return return_contacts;
              } else {
                return contact_data
              }
            } else {
                return contact_data;
            }
          }
        },
        methods: {
            addToFavourites(contact_id) {
                var favourite_contacts = localStorage.favourite_contacts

                if(typeof(favourite_contacts) !== "undefined") {
                    var favourite_ids = [];
                    if(favourite_contacts != "") {
                        favourite_ids = favourite_contacts.split(";")
                    }
                    var is_already_favourite = false
                    for(var i = 0; i < favourite_ids.length; i++) {
                        if(favourite_ids[i] == contact_id) {
                            favourite_ids.splice(i, 1)
                            is_already_favourite = true
                        }
                    }
                    if(!is_already_favourite) {
                        favourite_ids.push(contact_id)
                    }
                    if(favourite_ids.length > 0) {
                        favourite_contacts = favourite_ids.join(';')
                        localStorage.favourite_contacts = favourite_contacts
                    } else {
                        localStorage.removeItem("favourite_contacts")
                    }
                } else {
                    localStorage.favourite_contacts = contact_id
                }
                this.$emit('handleFavourites')
            }
        }
    }
</script>

<style scoped>
    .contact_pic {
        width: 100px;
        float: left;
        margin-right: 20px;
        margin-bottom: 20px;
    }
    .contact_pic img {
        max-width: 100%;
    }
    .contact_person_wrapper {
        padding: 30px 0px 20px 0px;
        border-bottom: 2px solid #E2E2E2;
        position: relative;
    }
    .contact_info {
        font-size: 14px;
        line-height: 1.6em;
        color: #484848;
        padding-left: 120px;
    }
    .contact_info.no_pic {
        padding-left: 0px;
    }
    .contact_info p {
        margin: 0px;
    }
    .contact_name {
        color: #00862F;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 14px;
      word-break: break-all;
    }
    .contact_info .contact_name {
        margin-top: 15px;
    }
    p.contact_text {
        margin-bottom: 20px;
    }
    .contact_to_favourites {
        position: absolute;
        right: 0px;
        top: 0px;
        height: 35px;
        line-height: 35px;
        padding-left: 30px;
        text-transform: uppercase;
        cursor: pointer;
        background: url(../../../assets/images/fav.svg) left 7px no-repeat;
        background-size: 20px;
        transition: all 0.2s ease;
    }
    .contact_to_favourites:hover, .contact_to_favourites.active {
        color: #00862F;
        background: url(../../../assets/images/fav_green.svg) left 7px no-repeat;
        background-size: 20px;
    }
    .more_contacts {
        font-size: 22px;
        margin-top: 30px;
        display: block;
    }
    .first_divider {
      padding-top: 20px !important;
    }
    .first_divider.more_contacts {
      margin-top: 0px;
    }
    .clearfix {
        clear: both;
    }
    .margin_contacts {
      margin-top: 10px;
    }
    @media only screen and (max-width: 380px) {
        .contact_info {
            padding-left: 0px;
        }
    }
</style>