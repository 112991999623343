import Vue from 'vue'
import App from './App.vue'
import vuetify from '@/plugins/vuetify'



Vue.config.productionTip = false

var vue_sidebar = new Vue({
  vuetify,
  render: h => h(App),
}).$mount('#service_menu');
window.vue_sidebar = vue_sidebar;
