<template>
    <div>
        <div v-for="item in openingsData" :key="item.id" class="accordion">
            <div class="header" :class="{ 'active' : item.active }" v-on:click="toggle(item.id)">
                {{ item.title }}
            </div>
            <transition name="accordion"
                        v-on:before-enter="beforeEnter" v-on:enter="enter"
                        v-on:before-leave="beforeLeave" v-on:leave="leave"
            >
                <div class="body" v-show="item.active">
                    <div class="body-inner" v-html="item.body"></div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
    export default {
        name: "OpeningHours",
        props: {
            openingsData: Array
        },
        methods: {
            toggle(acc) {
                for(var i = 0; i < this.openingsData.length; i++) {
                    if(this.openingsData[i].id == acc) {
                        this.openingsData[i].active = !this.openingsData[i].active
                    } else {
                        this.openingsData[i].active = false
                    }
                }
            },
            beforeEnter: function(el) {
                el.style.height = '0';
            },
            enter: function(el) {
                el.style.height = el.scrollHeight + 'px';
            },
            beforeLeave: function(el) {
                el.style.height = el.scrollHeight + 'px';
            },
            leave: function(el) {
                el.style.height = '0';
            }
        }
    }
</script>

<style scoped>

</style>