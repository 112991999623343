<template>
    <div class="functionsWrapper" style="padding-bottom:60px;">
        <div class="filterWrapper" :style="elWidth" :class="{ 'sticky' : searchBarFixed, 'show' : searchBarShow, 'transitionActive' : transitionActive }">
            <p style="margin-bottom: 15px; padding-top: 10px">Anzeige nach:</p>
            <div class="filterElement" @click="setActiveFilter('Abteilungen')" :class="{ 'active' : activeFilter == 'Abteilungen' }">Abteilungen</div>
            <div class="filterElement" @click="setActiveFilter('Personen')" :class="{ 'active' : activeFilter == 'Personen' }">Personen</div>
          <input v-model="searchInput" type="text" @input="handleSearch()" :placeholder="'Nach '+activeFilter+' suchen'">
        </div>
        <div class="sidebar_divider"></div>
        <Contact :contactData="activeContacts" active_el="kontakte" @handleFavourites="handleFavouriteContacts"></Contact>
        <transition name="fade">
            <div v-if="noContactFound" class="contact_person_wrapper" style="margin-top: 20px">
                Ihre Suche ergab leider keinen Treffer.
            </div>
        </transition>
    </div>
</template>

<script>
    import Contact from "@/components/sidebarComponents/childComponents/Contact";
    export default {
        name: "Contacts",
        props: {
          contactsData: Array
        },
        components: {
            Contact
        },
        data: () => ({
            elWidth: "width: 100%",
            scrollTop: 0,
            searchBarFixed: false,
            searchBarShow: false,
            transitionActive: false,
            activeFilter: "Abteilungen",
            searchInput: "",
            noContactFound: false,
            activeContacts: [],
            body_class: ""
        }),
        methods: {
            setSearchedName(searchedName) {
              this.activeFilter = 'Personen'
              this.searchInput = searchedName
              this.handleSearch()
            },
            setActiveFilter(fName) {
                this.activeFilter = fName
                localStorage.contactFilter = fName
                this.handleSearch()
            },
            handleScroll(elWidth,position,direction) {
                this.elWidth = "width: " + elWidth + "px"
                if(position > 350) {
                    this.searchBarFixed = true
                } else {
                    if(this.searchBarShow) {
                       if(position < 190) {
                           this.transitionActive = false
                           this.searchBarFixed = false
                           this.searchBarShow = false
                       }
                    } else {
                        this.transitionActive = false
                        this.searchBarFixed = false
                        this.searchBarShow = false
                    }
                }
                if(position > 725) {
                    this.transitionActive = true
                    if(direction == "up") {
                        this.searchBarShow = true
                    } else {
                        this.searchBarShow = false
                    }
                }
            },
            handleSearch() {
                localStorage.contactSearch = this.searchInput;
                this.activeContacts = [];
                var searchString = this.searchInput.toLowerCase();
                searchString = searchString.split(" ");
                if (this.searchInput == "") {
                    for(var i = 0; i < this.contactsData.length; i++) {
                        var person_abteilung = this.contactsData[i].person_abteilung;
                        if(person_abteilung) {
                          if((this.activeFilter === "Personen" && person_abteilung.toLowerCase() === "person") || (this.activeFilter === "Abteilungen" && person_abteilung.toLowerCase() === "abteilung")) {
                            this.activeContacts.push(this.contactsData[i]);
                          }
                        }
                    }
                } else {
                    for(i = 0; i < this.contactsData.length; i++) {
                        var add = true;
                        person_abteilung = this.contactsData[i].person_abteilung;
                      var vorname = "";
                      var nachname = "";
                        if(person_abteilung && person_abteilung.toLowerCase() === "person") {
                          vorname = this.contactsData[i].vorname;
                          if(vorname) {
                            vorname = vorname.toLowerCase();
                          }
                          nachname = this.contactsData[i].nachname;
                          if(nachname) {
                            nachname = nachname.toLowerCase();
                          }
                        }
                        var abteilung =  this.contactsData[i].abteilung.toLowerCase();
                        var suchbegriffe = this.contactsData[i].suchbegriffe;
                        if(suchbegriffe) {
                          suchbegriffe = suchbegriffe.toLowerCase();
                        }
                        var gesamtString = vorname + nachname + abteilung + suchbegriffe;


                        if(!(this.activeFilter === "Personen" && person_abteilung.toLowerCase() === "person") && !(this.activeFilter === "Abteilungen" && person_abteilung.toLowerCase() === "abteilung")) {
                            add = false;
                        } else {
                            for(var j = 0; j < searchString.length; j++) {
                                if(gesamtString.indexOf(searchString[j]) === -1) {
                                    add = false
                                }
                            }
                        }
                        if(add) {
                            this.activeContacts.push(this.contactsData[i]);
                        }
                    }
                }

                if(this.activeContacts.length == 0) {
                    this.noContactFound = true
                } else {
                    this.noContactFound = false
                }
                this.handleFavouriteContacts();
            },
            handleFavouriteContacts() {
                var favourite_contacts = localStorage.favourite_contacts
                if(typeof(favourite_contacts) !== "undefined") {
                    favourite_contacts = favourite_contacts.split(";")
                    for(var i = 0; i < this.activeContacts.length; i++) {
                        let id = this.activeContacts[i].id.toString()
                        let is_favourite = favourite_contacts.includes(id)

                        if(is_favourite) {
                            this.activeContacts[i]['is_favourite'] = true
                        } else {
                            this.activeContacts[i]['is_favourite'] = false
                        }
                    }
                } else {
                    for(i = 0; i < this.activeContacts.length; i++) {
                        this.activeContacts[i]['is_favourite'] = false
                    }
                }
                this.$emit('count_total');
            }
        },
        mounted() {
            if(localStorage.contactSearch) {
                this.searchInput = localStorage.contactSearch;
            }
            if(localStorage.contactFilter) {
              this.activeFilter = localStorage.contactFilter;
            }
            this.handleSearch();
        }
    }
</script>

<style scoped>
    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }
    .functionsWrapper {
        position: relative;
        padding-top: 200px
    }
    .filterWrapper p {
        text-transform: uppercase;
    }
    .filterElement {
        font-weight: bold;
        height: 40px;
        line-height: 40px;
        padding: 0px 15px;
        background: #C1C1C1;
        color: #fff;
        text-transform: uppercase;
        transition: all 0.2s ease;
        cursor: pointer;
        margin-right: 10px;
        float: left;
    }
    .filterElement.active {
        background: #00862F;
    }
    .filterWrapper {
        position: absolute;
        top: 0px;
        width: 100%;
        background: #fff;
        z-index: 8;
        max-width: 100%;
        box-sizing: content-box;
    }
    .filterWrapper.sticky {
        position: fixed;
        top: -85px;
        padding: 0px 60px;
        margin-left: -60px;
        border-bottom: 3px solid #E2E2E2;
        z-index: 1;
    }
    .filterWrapper.sticky.show {
        top: 80px;
    }
    .filterWrapper.transitionActive {
        transition: all 0.3s ease-in-out;
    }
    .filterWrapper input {
        width: 100%;
        margin-top: 20px;
        height: 40px;
        line-height: 40px;
        padding-left: 50px;
        padding-right: 5px;
        box-sizing: border-box;
        font-size: 14px;
        font-family: 'Open Sans', Arial, sans-serif;
        outline: none;
        appearance: none;
        border: 1px solid #BEBEBE;
        background: url(../../assets/images/search.svg) 10px center no-repeat;
        background-size: 25px;
        margin-bottom: 10px;
    }
    .sidebar_divider {
        height: 2px;
        width: 100%;
        background: #E2E2E2;
    }
</style>