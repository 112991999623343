<template>
    <div>
        <a @click="closeSidebar" class="banner_link banner_fuehrerschein" href="/themen/mobilitaet/fuehrerschein">Führerschein<span class="arrow"></span></a>
        <a @click="closeSidebar" class="banner_link banner_wunschkennzeichen" target="_blank" href="https://www.freistaat.bayern/dokumente/leistung/088532419499?plz=97616&behoerde=26220172442&gemeinde=921967646697">Wunschkennzeichen<span class="arrow"></span></a>
        <a @click="closeSidebar" class="banner_link banner_neuzulassung" target="_blank" href="https://www.freistaat.bayern/dokumente/leistung/3888675461?plz=97616&behoerde=26220172442&gemeinde=921967646697">Neuzulassung<span class="arrow"></span></a>
        <a @click="closeSidebar" class="banner_link banner_umschreibung" target="_blank" href="https://www.freistaat.bayern/dokumente/leistung/0666452463?plz=97616&behoerde=26220172442&gemeinde=921967646697">Umschreibung<span class="arrow"></span></a>
        <a @click="closeSidebar" class="banner_link banner_wiederzulassung" target="_blank" href="https://www.freistaat.bayern/dokumente/leistung/0666452463?plz=97616&behoerde=26220172442&gemeinde=921967646697">Wiederzulassung<span class="arrow"></span></a>
        <a @click="closeSidebar" class="banner_link banner_abmeldung" target="_blank" href="https://www.freistaat.bayern/dokumente/leistung/0888766265?plz=97616&behoerde=26220172442&gemeinde=921967646697">Abmeldung<span class="arrow"></span></a>
    </div>
</template>

<script>
    export default {
        name: "KFZ",
        methods: {
            closeSidebar() {
                this.$emit('closeSideBar');
            }
        }
    }
</script>

<style scoped>
    .banner_link:first-of-type {
        margin-top: 40px;
    }
    .banner_link {
        display: block;
        text-transform: uppercase;
        padding: 12px 0px;
        padding-left: 60px;
        position: relative;
        background: #F4F4F5;
        padding-right: 40px;
        margin-bottom: 15px;
        background-position: left 4px;
        background-repeat: no-repeat;
        background-size: auto 44px;
        box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.1);
        transition: all 0.2s ease-in-out;
    }
    .banner_link:hover {
        transform: scale(1.03);
    }
    .banner_link span {
        height: 30px;
        width: 30px;
        position: absolute;
        right: -8px;
        top: 0px;
        bottom: 0px;
        margin: auto;
        background: #00862F;
    }
    .banner_link span::after {
        content: "";
        position: absolute;
        left: 6px;
        top: 0px;
        bottom: 0px;
        margin: auto;
        height: 0px;
        border: solid #fff;
        border-width: 0 3px 3px 0;
        padding: 5px;
        transform: rotate(-45deg);
    }
    .banner_link::after {
        display: none;
    }
    .banner_link.banner_wunschkennzeichen {
        background-image: url(../../assets/images/banner-icon-19.svg);
    }
    .banner_link.banner_terminvergabe {
        background-image: url(../../assets/images/banner-icon-20.svg);
    }
    .banner_link.banner_neuzulassung {
        background-image: url(../../assets/images/banner-icon-21.svg);
    }
    .banner_link.banner_umschreibung {
        background-image: url(../../assets/images/banner-icon-22.svg);
    }
    .banner_link.banner_abmeldung {
        background-image: url(../../assets/images/banner-icon-23.svg);
    }
    .banner_link.banner_wiederzulassung {
        background-image: url(../../assets/images/banner-icon-24.svg);
    }
    .banner_link.banner_sonstige {
        background-image: url(../../assets/images/banner-icon-25.svg);
    }
    .banner_link.banner_fuehrerschein {
        background-image: url(../../assets/images/banner-icon-26.svg);
    }
</style>