<template>
  <iframe
      src="https://snippets.lkrg.net/abfuhrkalender_lkr_iframe/index.html"
      width="100%"
      height="800px"
      style="border:none;flex-grow: 1; border: none; margin: 0; padding: 0;"
  ></iframe>
</template>

<script>
export default {
  name: 'NewTrashSnippet',
  // mounted() {
  //   let snippetScript = document.createElement('script')
  //   snippetScript.setAttribute('src', 'https://snippets.sqronline.de/088d4688-284c-11ed-9377-6215551c4d98/snippet.js')
  //   document.head.appendChild(snippetScript)
  // }
}
</script>

<style scoped>

</style>